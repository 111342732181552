import axios from "axios";
import { FormPageData, OverView } from "../reducers/formReducer";
import {
  AnswerObject,
  BasicInfo,
  formInputInfo,
  PageKeyTable,
  PageStyling,
  RegistrationInfo,
  StatusTable,
} from "../types/types";
import * as config from "./config";

export type GetFormResponse = {
  status: StatusTable;
  registrationQuestions: formInputInfo[];
  pageStyling: PageStyling;
  registrationInfo: RegistrationInfo;
  maintenanceModeEndTime?: string;
  firstAvailablePositionInQue?: number;
} & BasicInfo &
  PageKeyTable;

const getForm = async (
  form: string
): Promise<{ data: GetFormResponse; status: number }> => {
  const url = `${config.baseUrl}/ereg/u/${form}`;
  return await axios({ url });
};

const getModificationForm = async (
  form: string,
  modificationToken: string
): Promise<{ data: any; status: number }> => {
  const url = `${config.baseUrl}/ereg/m/${form}/${modificationToken}`;
  return await axios({ url });
};

export type CancelFormResponse = {
  answerSummary: OverView;
  eventId: string;
  groupAnswer: AnswerObject[];
  personAnswer: AnswerObject[];
  pageStyling: PageStyling;
  pageStyleId: string;
  personUuid: string;
  queSize: string;
  registrationInfo: RegistrationInfo;
  personIsInGroup: boolean;
  subEventName: string;
  subEventRegistationCancelDate: string;
  subEventRegistationEndDate: string;
  subEventRegistationModifyDate: string;
  subEventRegistationStartDate: string;
  subEventType: string;
  status: StatusTable & { expiredToken: boolean };
  maintenanceModeEndTime?: string;
} & Pick<
  FormPageData,
  | "pageDescription"
  | "pageHeader"
  | "pageRegistrationFullText"
  | "pageRegistrationEndedText"
  | "pageRegistrationFullHeader"
  | "pageRegistrationEndedHeader"
  | "pageRegistrationNotYetOpenText"
  | "pageRegistrationNotYetOpenHeader"
  | "pageThankYouText"
  | "pageThankYouHeader"
  | "subEventId"
>;
const getCancelForm = async (
  form: string,
  cancelToken: string
): Promise<{ data: CancelFormResponse; status: number }> => {
  const url = `${config.baseUrl}/ereg/c/${form}/${cancelToken}`;
  return await axios({ url });
};

const formService = {
  getForm,
  getModificationForm,
  getCancelForm,
};

export default formService;
