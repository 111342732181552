import React, { useEffect } from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import allActions from '../../actions/allActions';

interface ReservationProps {
  amount: number
  expiration: string
  reservationToggle: string
  queued: boolean
  queuePosition?: number    
}

 export const ReservationBar: React.FC<ReservationProps> = ({ amount, expiration, reservationToggle, queued, queuePosition }) => {

const [left, setLeft] = useState('')
const textStyle = 'text-brand-dark_blue italic m-2 xl:text-lg xl:mx-10 mx-4'
const countDownDate = getCountDownDate(expiration)
const displayStrId = queued ? 'Form.queued' : 'Form.reservations'
const queuePositionDiv = queuePosition ? <span className={`${textStyle}`}><FormattedMessage id={'Form.queuePosition'} />: {queuePosition}</span> : null 


const dispatch = useDispatch()

function getCountDownDate(expiration: string){
    //TODO: Parempi fiksaus tänne, joka kattaa kaikki käyttötarkoitukset
    const regex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}\s[0-9]{2}:[0-9]{2}:[0-9]{2}/ //eslint-disable-line
    if(expiration && expiration.match(regex)){
        const timeArr = expiration.split(' ') //expected format 2020-12-29 15:39:28
        const dateValues = timeArr[0].split('-')
        const timeValues = timeArr[1].split(':')
        return new Date(Number(dateValues[0]), Number(dateValues[1]) -1, Number(dateValues[2]), Number(timeValues[0]), Number(timeValues[1]), Number(timeValues[2])).getTime()
    }
   return null
}


const getTimeLeft = () => {
        const now = new Date().getTime()
        const distance = countDownDate - now
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        const seconds = Math.floor((distance % (1000 * 60)) / 1000)
        if (distance < 0) {
            setLeft(`Reservation.Expire`)
            dispatch(allActions.registrationActions.removeUuids(reservationToggle))
        } else {
            setLeft(`${minutes}:${addZero(seconds)}`)
        }
}


const addZero = (x: number) => {
    if(x.toString().length === 1){
        return `0${x}`
    }
    return x
}


useEffect(()=>{

    let cancel = false

    const interval = setInterval(() => {
        if (!cancel) { 
            if(left.includes(`Reservation`)){
                clearInterval(interval)
                setLeft('')
            } else {
                getTimeLeft() 
            }
        }
    }, 1000)
    
    return () => {
        cancel = true //cancel interval when component unmounting
        clearInterval(interval)}

  }, [expiration]) //eslint-disable-line

  

  return (
        <div className={`bg-brand-white w-full flex justify-between xl:pb-5 pb-4`}>
            <span className={textStyle}><FormattedMessage id={displayStrId} />: {amount}</span>
            {queuePositionDiv}
            <span className={textStyle}>{left.includes(`Reservation`) ? <FormattedMessage id={left} /> : <span>{left}</span>}</span>
        </div>
  );
}

export default ReservationBar;

