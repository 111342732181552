import { Cmd, Loop, loop, LoopReducer } from 'redux-loop';
import { ActionType, createAsyncAction, getType } from 'typesafe-actions';
import registerService from '../services/register';
import {   RegisterState, Summary } from '../types/types'

const initialState: RegisterState = {
  registered: false
};

export const registrationAsync = createAsyncAction(
    'START_REGISTRATION',
    'REGISTRATION_COMPLETE',
    'REGISTRATION_FAIL'
  )<Summary, {data: any, status: number }, Error>()


type Action = 
| ActionType<typeof registrationAsync>


export const registerReducer: LoopReducer<RegisterState, Action> = (state: RegisterState = initialState, action: Action): RegisterState | Loop<RegisterState> => {
  switch (action.type) {
         //register
         case getType(registrationAsync.request):
            return loop(
                {...state },
                Cmd.run(registerService.register, {
                    successActionCreator: registrationAsync.success,
                    failActionCreator: registrationAsync.failure,
                    args: [action.payload]
                })
            )
        case getType(registrationAsync.success):
          if(action.payload.status === 200){
              return {
                ...state, 
                registered: true
                }
          } else {
              return state
          }
      case getType(registrationAsync.failure):
        return state
    default:
      return state;
  }
};