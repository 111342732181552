import React from "react";
import { FormattedMessage } from "react-intl";
import { AnswerObject } from "../../types/types";

interface InfoCardProps {
  data: AnswerObject[];
  width?: string;
  title: string;
  index?: number;
}

export const InfoCard: React.FC<InfoCardProps> = ({
  data,
  width,
  title,
  index,
}) => {
  const w = width ? width : "lg:w-1/2 w-11/12";

  return (
    <div
      className={`${w} self-center bg-blue-200 xl:m-2 m-2 xl:p-4 p-1 rounded shadow-md`}
    >
      <span className="font-bold">
        <FormattedMessage id={title} />
        {index ? <span> {index}</span> : null}
      </span>
      <div className="flex flex-col">
        {data.map((obj, i) => {
          const answer =
            typeof obj.answer === "string" ? obj.answer : obj.answer.join(", ");
          return (
            <span key={i}>
              {obj.question}: {answer}
            </span>
          );
        })}
      </div>
    </div>
  );
};

export default InfoCard;
