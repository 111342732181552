export const arrayToObject = (array: { [key: string]: any }[], key: string) =>
  array.reduce((obj, item) => {
    obj[item[key]] = item;
    return obj;
  }, {});

export const concatNarrays = (arr: string[][]) => {
  const newArr = arr.reduce((prev, next) => {
    return prev.concat(next);
  });
  return newArr;
};

export function formatLink(link: string) {
  if (link.substr(0, 4) === "http") {
    return link;
  } else {
    return `http://${link}`;
  }
}

export const getOperation = (id: string, operation: string) => {
  if (id.length === 1) {
    return id;
  }
  if (operation && operation.length === 1) {
    return operation;
  }
  return null;
};

export const getUuid = (obj: object) => {
  for (const key of Object.keys(obj)) {
    if (
      typeof obj[key] === "object" &&
      obj[key] !== null &&
      !Array.isArray(obj[key])
    ) {
      return key;
    }
  }
};

export const getCancelIndex = (obj: object) => {
  for (const key of Object.keys(obj)) {
    if (obj[key] === "index") {
      return obj[key] - 1;
    }
    return 0;
  }
};
