import { LoopReducer } from 'redux-loop';
import { ActionType, getType } from 'typesafe-actions';
import {  LoadingState } from '../types/types'
import { cancelFormAsync, getFormAsync, getModificationFormAsync } from './formReducer';
import { registrationAsync } from './registerReducer';

const initialState: LoadingState = {
  loading: false
};

type Action = 
| ActionType<typeof getFormAsync>
| ActionType<typeof registrationAsync>
| ActionType<typeof getModificationFormAsync>
| ActionType<typeof cancelFormAsync>


export const loadingReducer: LoopReducer<LoadingState, Action> = (state: LoadingState = initialState,action: Action): LoadingState => {
  switch (action.type) {
    case getType(getFormAsync.request):
      return {...state, loading: true};
    case getType(getFormAsync.success):
        return {...state, loading: false};
    case getType(getModificationFormAsync.request):
      return {...state, loading: true};
    case getType(getModificationFormAsync.success):
      return {...state, loading: false};
    case getType(getModificationFormAsync.failure):
        return {...state, loading: false};
    case getType(getFormAsync.failure):
        return {...state, loading: false};
    case getType(registrationAsync.request):
      return {...state, loading: true};
    case getType(registrationAsync.success):
        return {...state, loading: false};
    case getType(registrationAsync.failure):
        return {...state, loading: false};
    case getType(cancelFormAsync.request):
      return {...state, loading: true};
    case getType(cancelFormAsync.success):
      return {...state, loading: false};
    case getType(cancelFormAsync.failure):
        return {...state, loading: false};
    default:
      return state;
  }
};