import toggleActions from './toggleActions'
import formActions from './formActions'
import registrationActions from './registrationActions'

const allActions = {
    toggleActions,
    formActions,
    registrationActions
}

export default allActions