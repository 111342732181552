import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import allActions from "../actions/allActions";
import { Loading } from "../components/utils/Loading";
import { FormParams } from "../types/types";
import * as config from "../services/config";
import { Message } from "../components/utils/Message";
import { AlertDialog } from "../components/modals/AlertDialog";
import { Logo } from "../components/utils/Logo";
import { Toggle } from "../components/utils/Toggle";
import { Summary as SummaryComponent } from "../components/summary/Summary";
import SingleModification from "../components/form/SingleModificationContainer";
import ConsoleHelper from "../utils/logger";
import { selectModificationPageData } from "../reducers/formReducer";
import { AppState } from "../reducers/combineReducer";
import { getOperation } from "../utils/functions";

export const ModificationPage: React.FC = () => {
  const { id, token, operation, domain } = useParams<FormParams>();
  const name = domain ? `${domain}/${id}` : id;
  const formName = name.length > 1 ? name : operation;
  const formOperation = getOperation(name, operation);
  const formToken = token ? token : null;
  const dispatch = useDispatch();
  const {
    items,
    registrationFull,
    registered,
    initLoading,
    message,
    showRegistration,
    pageHeader,
    pageDescription,
    registrationEnded,
    pageRegistrationEndedText,
    pageRegistrationEndedHeader,
    overview,
    pageThankYouHeader,
    pageThankYouText,
    maintenanceMode,
    maintenanceModeEndTime,
    logoUrl,
    subEventId,
    cancelIndex,
    personIsInGroup,
    pageRegistrationFullHeader,
    pageRegistrationFullText,
    pageRegistrationNotYetOpenHeader,
    pageRegistrationNotYetOpenText,
  } = useSelector((state: AppState) => selectModificationPageData(state, name));

  const formStyle = "flex flex-col bg-brand-white lg:p-8 pt-4";
  const cancelToggle = "CANCEL_TOGGLE";
  const buttonInfo = {
    style: "bg-blue-400 hover:bg-brand-blue text-brand-white",
    format_id: "EventRegistration.confirm_registration",
  };

  const getForm = useCallback(() => {
    if (formOperation) {
      if (formOperation === "m") {
        dispatch(
          allActions.formActions.startModificationFormFetch(formName, formToken)
        );
      } else if (formOperation === "c") {
        dispatch(allActions.formActions.startCancelFetch(formName, formToken));
      }
    } else {
      dispatch(allActions.formActions.startFormFetch(formName));
    }
  }, [dispatch, formOperation, formName, formToken]);

  const removeMessage = () => {
    dispatch(allActions.formActions.removeMessage());
  };

  const cancelRegistration = () => {
    dispatch(allActions.registrationActions.startCancel(token, subEventId));
  };

  const alertProps = {
    message,
    ...(formOperation !== "c" && { alertAction: removeMessage }),
  };

  useEffect(() => {
    getForm();
  }, [getForm]);

  return (
    <div className="flex flex-col content-center h-full max-h-11/12 xl:w-1/2 w-11/12 m-auto">
      {initLoading ? (
        <Loading />
      ) : message ? (
        <>
          <Logo url={`${process.env.PUBLIC_URL}/${config.defaultLogoUrl}`} />
          <AlertDialog {...alertProps} />
        </>
      ) : showRegistration && formOperation === "m" ? (
        <>
          <Logo url={logoUrl} />
          <SingleModification
            title={pageHeader}
            description={pageDescription}
            list={items}
            formStyle={formStyle}
            buttonInfo={buttonInfo}
            formName={id}
            formAction={() => ConsoleHelper("sending modification data")}
          />
        </>
      ) : showRegistration && formOperation === "c" ? (
        <>
          <Logo url={logoUrl} />
          <Toggle id={cancelToggle}>
            <AlertDialog
              message={
                personIsInGroup
                  ? "RESERVATION CANCEL GROUP"
                  : "RESERVATION CANCEL"
              }
              alertAction={cancelRegistration}
              summary={[overview.info, overview.participants[cancelIndex]]}
            />
          </Toggle>
        </>
      ) : registered ? (
        <>
          <Logo url={logoUrl} />
          <Message
            messageHeader={pageThankYouHeader}
            message={pageThankYouText}
            additionalContent={true}
          />
          <SummaryComponent data={overview} />
        </>
      ) : registrationFull ? (
        <>
          <Logo url={logoUrl} />
          <Message
            messageHeader={pageRegistrationFullHeader}
            message={pageRegistrationFullText}
            additionalContent={false}
          />
        </>
      ) : registrationEnded ? (
        <>
          <Logo url={logoUrl} />
          <Message
            messageHeader={pageRegistrationEndedHeader}
            message={pageRegistrationEndedText}
            additionalContent={false}
          />
        </>
      ) : maintenanceMode ? (
        <>
          <Logo url={logoUrl} />
          <Message
            messageHeader={"Maintenance"}
            message={
              maintenanceModeEndTime
                ? `Maintenance planned to end ${maintenanceModeEndTime}`
                : `Site is down for maintenance for a bit`
            }
            additionalContent={false}
          />
        </>
      ) : (
        <>
          <Logo url={logoUrl} />
          <Message
            messageHeader={pageRegistrationNotYetOpenHeader}
            message={pageRegistrationNotYetOpenText}
            additionalContent={false}
          />
        </>
      )}
    </div>
  );
};

export default ModificationPage;
