import React from "react";
import { AnswerObject } from "../../types/types";
import { InfoCard } from "./InfoCard";

interface SummaryProps {
  data: {
    participants: AnswerObject[][];
    info: AnswerObject[];
  };
}

export const Summary: React.FC<SummaryProps> = ({ data }) => {
  const participantDivWidth =
    data.participants.length === 1 ? "xl:w-1/2 w-11/12" : "lg:w-1/3 w-full";

  return (
    <div className="container max-h-3/4 overflow-x-auto scroller shadow-xl">
      <div className="flex flex-col bg-brand-white pb-4 rounded-bl">
        {data.info.length > 0 && (
          <InfoCard title={`Summary.Info_Header`} data={data.info} />
        )}
        <div className="flex flex-wrap justify-around">
          {data.participants.map((participantData, index) => (
            <InfoCard
              key={index}
              title={`Summary.Participant_Header`}
              index={index + 1}
              data={participantData}
              width={participantDivWidth}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Summary;
