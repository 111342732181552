import { defineMessage } from "react-intl";

export const messages = defineMessage({
  any: {
    id: "Form.required_any",
    defaultMessage: "This field is required!",
  },
  phone: {
    id: "Invalid phonenumber!",
    defaultMessage: "Invalid phonenumber!",
  },
  email: {
    id: "Invalid email address!",
    defaultMessage: "Invalid email address!",
  },
});
