const showToggle = (id: string) => {
    return {
        type: 'SHOW',
        payload: id
    }
}

const hideToggle = (id: string) => {
    return {
        type: 'HIDE',
        payload: id
    }
}

const toggleActions = {
    showToggle,
    hideToggle
}

export default toggleActions