import { LoopReducer } from 'redux-loop';
import { ActionType, getType } from 'typesafe-actions';
import {  ErrorState } from '../types/types'
import { cancelFormAsync, getFormAsync, getModificationFormAsync, unreserveAsync } from './formReducer';
import { registrationAsync } from './registerReducer';

const initialState: ErrorState = {
  message: ''
};

type Action = 
| ActionType<typeof getFormAsync>
| ActionType<typeof registrationAsync>
| ActionType<typeof getModificationFormAsync>
| ActionType<typeof cancelFormAsync>
| ActionType<typeof unreserveAsync>

export const errorReducer: LoopReducer<ErrorState, Action> = (state: ErrorState = initialState,action: Action): ErrorState => {
  switch (action.type) {

    case getType(getFormAsync.success):
        return {...state,
            message: null}
    case getType(getFormAsync.failure):
        return {...state, 
            message: action.payload.message};
    case getType(cancelFormAsync.success):
      return {...state,
          message: null}
    case getType(cancelFormAsync.failure):
      return {...state, 
          message: action.payload.message};
    case getType(getModificationFormAsync.success):
        return {...state,
            message: null}
    case getType(getModificationFormAsync.failure):
        return {...state, 
            message: action.payload.message};
    case getType(registrationAsync.success):
      return {...state,
          message: null}
    case getType(registrationAsync.failure):
          return {...state, 
            message: action.payload.message};
    case getType(unreserveAsync.success):
      return {...state,
          message: null}
    case getType(unreserveAsync.failure):
        return {...state, 
            message: action.payload.message};
    default:
      return state;
  }
};