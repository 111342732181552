import { Cmd, Loop, loop, LoopReducer } from "redux-loop";
import {
  ActionType,
  createAction,
  createAsyncAction,
  getType,
} from "typesafe-actions";
import registerService, {
  CancelRegistrationResponse,
} from "../services/register";
import { ReservationState } from "../types/types";
import { getUuid } from "../utils/functions";
import {
  cancelFormAsync,
  getFormAsync,
  getModificationFormAsync,
  reserveAsync,
  reserveRollBackValue,
  reserveSuccess,
  unreserveAsync,
} from "./formReducer";

const initialState: ReservationState = {
  uuids: [],
  reserveExpireTime: "",
  initLoading: false,
  queued: false,
  expired: false,
  expiredAmount: 0,
};

export const removeUuids = createAction("REMOVEUUIDS")<{ toggle: string }>();
export const rereserveAsync = createAsyncAction(
  "START_RERESERVE",
  "RERESERVE_COMPLETE",
  "RERESERVE_FAIL"
)<
  { amount: number; subEventId: string },
  { data: any; status: number },
  Error
>();

export const cancelRegistrationAsync = createAsyncAction(
  "START_CANCEL_REGISTRATION",
  "CANCEL_REGISTRATION_COMPLETE",
  "CANCEL_REGISTRATION_FAIL"
)<
  { token: string; subEventId: string },
  { data: CancelRegistrationResponse; status: number },
  Error
>();
type Action =
  | ActionType<typeof reserveAsync>
  | ActionType<typeof cancelRegistrationAsync>
  | ActionType<typeof rereserveAsync>
  | ActionType<typeof unreserveAsync>
  | ActionType<typeof getFormAsync>
  | ActionType<typeof getModificationFormAsync>
  | ActionType<typeof cancelFormAsync>
  | ActionType<typeof removeUuids>
  | ActionType<typeof reserveSuccess>
  | ActionType<typeof reserveRollBackValue>;

export const reservationReducer: LoopReducer<ReservationState, Action> = (
  state: ReservationState = initialState,
  action: Action
): ReservationState | Loop<ReservationState> => {
  switch (action.type) {
    //reserve
    case getType(reserveSuccess):
      if (action.payload.status === 200) {
        const earlierTime =
          Date.parse(state.reserveExpireTime) <
          Date.parse(action.payload.data.reserveExpireTime)
            ? state.reserveExpireTime
            : action.payload.data.reserveExpireTime;
        return {
          ...state,
          uuids: state.uuids.concat(action.payload.data.uuids),
          reserveExpireTime: earlierTime,
          initLoading: false,
          queued: action.payload.data.state.quedRegistation,
          expired: false,
          expiredAmount: 0,
        };
      }
      return { ...state, initLoading: false };

    case getType(reserveRollBackValue):
      return { ...state, initLoading: false };

    case getType(unreserveAsync.success):
      if (action.payload.status === 200) {
        const copyOfUuids = [...state.uuids];
        copyOfUuids.splice(
          copyOfUuids.length - action.payload.data.deletedUuids.length,
          action.payload.data.deletedUuids.length
        );
        return {
          ...state,
          uuids: copyOfUuids,
          initLoading: false,
        };
      }
      return { ...state, initLoading: false };

    //re-reserve
    case getType(rereserveAsync.request):
      return loop(
        {
          ...state,
          initLoading: true,
        },
        Cmd.run(registerService.reserve, {
          successActionCreator: rereserveAsync.success,
          failActionCreator: rereserveAsync.failure,
          args: [action.payload.amount, action.payload.subEventId],
        })
      );

    case getType(rereserveAsync.success):
      if (action.payload.status === 200) {
        const earlierTime =
          Date.parse(state.reserveExpireTime) <
          Date.parse(action.payload.data.reserveExpireTime)
            ? state.reserveExpireTime
            : action.payload.data.reserveExpireTime;
        return {
          ...state,
          uuids: state.uuids.concat(action.payload.data.uuids),
          reserveExpireTime: earlierTime,
          initLoading: false,
          expired: false,
          expiredAmount: 0,
        };
      }
      return { ...state, initLoading: false };

    case getType(rereserveAsync.failure):
      return {
        ...state,
        initLoading: false,
      };

    case getType(removeUuids):
      return {
        ...state,
        uuids: [],
        reserveExpireTime: null,
        expired: true,
        expiredAmount: state.uuids.length,
      };

    case getType(getFormAsync.success):
      if (
        action.payload.status === 200 &&
        action.payload.data.firstAvailablePositionInQue
      ) {
        return {
          ...state,
          firstAvailablePositionInQue:
            action.payload.data.firstAvailablePositionInQue,
        };
      }
      return state;

    case getType(getModificationFormAsync.success):
      if (action.payload.status === 200) {
        const uuid = getUuid(
          action.payload.data.registerQuestionAnswers.participants
        );
        return {
          ...state,
          uuids: [uuid],
        };
      }
      return state;

    case getType(cancelFormAsync.success):
      if (action.payload.status === 200) {
        const uuid = action.payload.data.personUuid;
        return { ...state, uuids: [uuid] };
      }
      return state;

    case getType(cancelRegistrationAsync.request):
      if (state.uuids.length > 0) {
        const personUuid = state.uuids[0];
        const { token, subEventId } = action.payload;
        const data = { personUuid, token, subEventId };

        return loop(
          {
            ...state,
            initLoading: true,
          },
          Cmd.run(registerService.cancelRegistration, {
            successActionCreator: cancelRegistrationAsync.success,
            failActionCreator: cancelRegistrationAsync.failure,
            args: [data],
          })
        );
      }
      return state;

    case getType(cancelRegistrationAsync.success):
      if (action.payload.status === 200) {
        return {
          ...state,
          uuids: [],
          initLoading: false,
        };
      }
      return { ...state, initLoading: false };

    case getType(cancelRegistrationAsync.failure):
      return {
        ...state,
        initLoading: false,
      };

    default:
      return state;
  }
};
