import React from "react";
import { FormattedMessage } from "react-intl";
import { AnswerObject } from "../../types/types";
import { InfoCard } from "../summary/InfoCard";
import { headers, messages } from "./messages";

interface AlertDialogProps {
  message: string;
  styles?: string;
  alertAction?: () => void;
  cancelAction?: () => void;
  summary?: AnswerObject[][];
}

export const AlertDialog: React.FC<AlertDialogProps> = ({
  message,
  styles,
  alertAction,
  summary,
  cancelAction,
}) => {
  const style = styles !== undefined ? styles : "container";

  const summaryDiv = summary
    ? summary.map((info, index) => {
        const title =
          index === 0 ? "Summary.Info_Header" : "Summary.Participant_Header";
        return <InfoCard key={index} data={info} title={title} />;
      })
    : null;

  const buttonFlex = cancelAction ? "flex justify-center" : "";

  const cancelButtonDiv = cancelAction ? (
    <button
      className="bg-blue-400 hover:bg-brand-blue text-brand-white py-2 px-4 m-5 w-auto rounded"
      onClick={() => cancelAction()}
    >
      <FormattedMessage id="AlertDialog.cancel_button" />
    </button>
  ) : null;

  const header = headers[message] ?? headers.GENERIC;
  const paragraph = messages[message] ?? messages.GENERIC;

  return (
    <div className={style}>
      <div className="flex flex-col bg-brand-white rounded-b pt-5 text-center">
        <span className="text-brand-dark_blue capitalize font-bold m-4">
          <FormattedMessage id={header} />
        </span>
        <span className="text-brand-dark_blue text-center mb-4">
          <FormattedMessage id={paragraph} />
        </span>
        {summaryDiv}
        <div className={`pb-2 ${buttonFlex}`}>
          {alertAction && (
            <button
              className="bg-blue-400 hover:bg-brand-blue text-brand-white py-2 px-4 m-5 w-auto rounded"
              onClick={() => alertAction()}
            >
              <FormattedMessage id="AlertDialog.confirm_button" />
            </button>
          )}
          {cancelButtonDiv}
        </div>
      </div>
    </div>
  );
};

export default AlertDialog;
