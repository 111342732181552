import React from "react";
import { LinkLabel } from "./LinkLabel";
import { LabelWithHelpIcon } from "./LabelWithHelpIcon";
import { Label } from "./Label";
import { AdditionalLabel } from "./AdditionalLabel";

interface Props {
  link: string | boolean;
  overlayToggle?: (id: string) => void;
  overlayId?: string;
  question: string;
  labelStyle?: string;
  cursiveLabel?: string;
  register?: any;
}

export const LabelContainer: React.FC<Props> = ({
  register,
  link,
  overlayToggle,
  overlayId,
  question,
  labelStyle,
  cursiveLabel,
}) => {
  return (
    <>
      {typeof link === "string" ? (
        <LinkLabel link={link} question={question} register={register} />
      ) : question && overlayId ? (
        <LabelWithHelpIcon
          labelStyle={labelStyle}
          register={register}
          question={question}
          overlayId={overlayId}
          overlayToggle={overlayToggle}
        />
      ) : question ? (
        <Label
          question={question}
          register={register}
          labelStyle={labelStyle}
        />
      ) : null}
      <AdditionalLabel cursiveLabel={cursiveLabel} />
    </>
  );
};
