import axios from "axios";
import ConsoleHelper from "../utils/logger";
import * as config from "./config";

axios.interceptors.request.use((x) => {
  ConsoleHelper(x);
  return x;
});

type ReservationStates = "normalRegistration" | "quedRegistation";
type ReserveErrors = "eventFullNoQue" | "oversizedGroup";
export type ReservationResponse = {
  subEventId: string;
  reserveExpireTime: string;
  state: Record<ReservationStates, boolean>;
  uuids: string[];
  status?: Record<ReserveErrors, boolean>;
};

const reserve = async (
  amount: number,
  subEventId: string
): Promise<{ data: ReservationResponse; status: number }> => {
  const url = `${config.baseUrl}/ereg/reserve/${subEventId}/${amount}`;
  return await axios({ url });
};

const unreserve = async (obj: { subEventId: string; uuids: string[] }) => {
  const url = `${config.baseUrl}/ereg/reserve`;
  return await axios({ method: "DELETE", url, data: obj });
};

const register = async (data: any) => {
  const url = `${config.baseUrl}/ereg/register`;
  return await axios({ method: "POST", url, data });
};

type CancelPayload = {
  subEventId: string;
  token: string;
  personUuid: string;
};
export type CancelRegistrationResponse = {
  personRegistrationStatus: "userCanceled";
} & Omit<CancelPayload, "token">;
const cancelRegistration = async (
  data: CancelPayload
): Promise<{ data: CancelRegistrationResponse; status: number }> => {
  const url = `${config.baseUrl}/ereg/c`;
  return await axios({ method: "POST", url, data });
};

const registerService = {
  reserve,
  unreserve,
  register,
  cancelRegistration,
};

export default registerService;
