import React from "react";

interface Props {
  cursiveLabel?: string;
}

export const AdditionalLabel: React.FC<Props> = ({ cursiveLabel }) => {
  if (!cursiveLabel) {
    return null;
  }
  return <p className="text-brand-dark_blue italic ml-4">{cursiveLabel}</p>;
};
