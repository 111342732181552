export const headers = {
  UNRESERVE_FAIL: "Form.Error_Unreserve_Header",
  "RESERVATION FAIL": "Form.Error_Reserve_Header",
  "REGISTRATION FAIL": "Form.Error_Registration_Header",
  "EVENT FULL, NO REGISTRATION ALLOWED": "Form.Error_EventFull_Header",
  "TOO LARGE GROUP": "Form.Error_EventLargeGroup_Header",
  "REGISTRATION TO QUEUE": "Form.Error.Reservation_Expire_Header",
  "ONGOING MAINTENANCE": "Form.MaintenanceMode_Header",
  "EXPIRED MODIFY TOKEN": "Form.ExpiredTokenModify_Header",
  "EXPIRED CANCEL TOKEN": "Form.ExpiredTokenCancel_Header",
  "RESERVATION CANCEL": "Form.ReservationCancel_Header",
  "RESERVATION CANCEL GROUP": "Form.ReservationCancel_Header",
  "CANCEL REGISTRATION FAIL": "Form.Error_Registration_Cancel_Header",
  "CANCEL REGISTRATION SUCCESS": "Form.Success_Registration_Cancel_Header",
  GENERIC: "Form.Error.Generic_Header",
};
export const messages = {
  UNRESERVE_FAIL: "Form.Error_Unreserve",
  "RESERVATION FAIL": "Form.Error_Reserve",
  "REGISTRATION FAIL": "Form.Error_Registration",
  "EVENT FULL, NO REGISTRATION ALLOWED": "Form.Error_EventFull",
  "TOO LARGE GROUP": "Form.Error_EventLargeGroup",
  "REGISTRATION TO QUEUE": "Form.Error.Reservation_Expire",
  "ONGOING MAINTENANCE": "Form.MaintenanceMode",
  "EXPIRED MODIFY TOKEN": "Form.ExpiredTokenModify",
  "EXPIRED CANCEL TOKEN": "Form.ExpiredTokenCancel",
  "RESERVATION CANCEL": "Form.ReservationCancel",
  "RESERVATION CANCEL GROUP": "Form.ReservationCancel",
  "CANCEL REGISTRATION FAIL": "Form.Error_Registration_Cancel",
  "CANCEL REGISTRATION SUCCESS": "Form.Success_Registration_Cancel",
  "RESERVATION EXPIRE": "Reservation.Expire",
  GENERIC: "Form.Error.Generic_Header",
};

export type MessageKey = keyof typeof headers;
