const updateValue = (id: string, value: string, form: string) => {
    return {
        type: 'UPDATEVALUE',
        payload: {id,value,form}
    }
}


const updateDateValue = (id: string, value: Date, form: string) => {
    return {
        type: 'UPDATEDATEVALUE',
        payload: {id, value, form}
    }
}


const addToValue = (id: string, currArray: string[], value: string, form: string) => {
    const result: string[] = 
    currArray.length === 0 ? [value]
    : currArray.includes(value) ? currArray.filter(val => val !== value)
    : currArray.concat(value)
    return {
        type: 'ADDTOVALUE',
        payload: {id,value:result,form}
    }
}


const startFormFetch = (form: string) => {
    return {
        type: 'START_FORM_FETCH',
        payload: form
    }
}

const startModificationFormFetch = (form: string, modificationToken: string) => {
    return {
        type: 'START_MODIFICATION_FORM_FETCH',
        payload: {form, modificationToken}
    }
}

const startCancelFetch = (form: string, cancelToken: string) => {
    return {
        type: 'START_CANCEL_FORM_FETCH',
        payload: {form, cancelToken}
    }
}


const removeKey = (id: string, form: string) => {
    return {
        type: 'REMOVEKEY',
        payload: {form, id}
    }
}

const removeMessage = () => {
    return {
        type: 'REMOVEMESSAGE'
    }
}

const setPage = (page: number, highest: number) => {
    return {
        type: 'SETPAGE',
        payload: {page, highest}
    }
}


const formActions = {
    updateValue,
    addToValue,
    updateDateValue,
    startFormFetch,
    removeKey,
    removeMessage,
    setPage,
    startModificationFormFetch,
    startCancelFetch
}

export default formActions