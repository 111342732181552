import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './tailwind.output.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import rootReducer from './reducers/combineReducer'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { createStore } from 'redux';
import { install, StoreCreator } from 'redux-loop';
import { IntlProvider } from 'react-intl'
import messages_fi from './lang/fi.json';
import messages_en from './lang/en.json';
import fi from 'date-fns/locale/fi';
import { setDefaultLocale, registerLocale } from 'react-datepicker';

const messages: any = {
    'fi': messages_fi,
    'en': messages_en
};

const enhancer = composeWithDevTools(install())
const enhancedCreateStore = createStore as StoreCreator;


registerLocale('fi', fi);
setDefaultLocale('fi')
const store = enhancedCreateStore(rootReducer, {}, enhancer);

const language: string = navigator.language.split(/[-_]/)[0];
localStorage.setItem('language', language)

ReactDOM.render(
  <IntlProvider locale={language} messages={messages[language]}>
    <Provider store={store}>
        <App />
    </Provider>
  </IntlProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
