import React from 'react'

interface LogoProps {
    url: string
    w?: string
  }
  
  export const Logo: React.FC<LogoProps> = ({ url, w }) => {

    const width = w ? w : 'xl:pt-6 pt-1 xl:px-4 px-2 xl:pb-4'

    return (
      <div className={`${width} bg-brand-white text-center`}>
        <img 
          src={url}
          alt='brand logo'
          className='object-scale-down m-auto'/>
        </div>
    )
  }


  export default Logo;