import React from "react";

interface IconWrapperProps {
  styles: string;
}

export const IconWrapper: React.FC<IconWrapperProps> = ({
  children,
  styles,
}) => {
  return <span className={styles}>{children}</span>;
};
