import React from "react";
import { formatLink } from "../../../utils/functions";
import { RequiredAsterisk } from "./RequiredAsterisk";

interface Props {
  link: string;
  question: string;
  register?: any;
}

export const LinkLabel: React.FC<Props> = ({ link, question, register }) => {
  const linkStyles = "hover:text-brand-dark_blue text-brand-blue xl:mb-2";

  return (
    <span className={`${linkStyles} xl:mt-3 mt-6`}>
      <a href={formatLink(link)} target="_blank" rel="noopener noreferrer">
        {question}
        <RequiredAsterisk register={register} />
      </a>
    </span>
  );
};
