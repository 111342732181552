import React, { useEffect, useRef } from "react";
import { FormInput } from "./FormInput";
import { FormSelect } from "./FormSelect";
import { FormTextArea } from "./FormTextArea";
import { formInputInfo, buttonInfo } from "../../types/types";
import allActions from "../../actions/allActions";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { FormRadio } from "./FormRadio";
import FormCheckBox from "./FormCheckBox";
import { useForm } from "react-hook-form";
import {
  sortByGroup,
  focusOnErrors,
  getProductSummary,
  returnRequiredRule,
} from "../../utils/formFunctions";
import { selectFormItemsAndValues } from "../../reducers/formReducer";
import { AppState } from "../../reducers/combineReducer";

interface ModificationProps {
  formAction?: (data: { [key: string]: string | string[] }) => void;
  title?: string;
  description?: string;
  list: formInputInfo[];
  formStyle: string;
  buttonInfo: buttonInfo;
  message?: string;
  formName: string;
}

export const SingleModification: React.FC<ModificationProps> = ({
  title,
  formStyle,
  formAction,
  message,
  formName,
  description,
}) => {
  const { register, handleSubmit, trigger, errors } = useForm();
  const dispatch = useDispatch();
  const { items, values, currentPage } = useSelector((state: AppState) =>
    selectFormItemsAndValues(state, formName)
  );
  const pageList = items.filter((item) => item.page === currentPage);
  let prevGroup = pageList.length > 0 ? pageList[0].group : 0;
  const summary = getProductSummary(items, values);
  const formRef = useRef(null);

  const messageDiv =
    message !== "" ? (
      <div className="text-red-400 h-2 pb-4">{message}</div>
    ) : (
      <div className="invisible h-2 pb-2">{message}</div>
    );
  const titleDiv = title ? (
    <div className="text-center text-brand-dark_blue lg:m-4 font-semibold text-3xl">
      <span className="text-brand-dark_blue">{title}</span>
    </div>
  ) : null;
  const descriptionDiv = description ? (
    <div className="text-center text-brand-dark_blue lg:m-4 m-2 italic text-lg xl:pb-5">
      <span className="text-brand-dark_blue">{description}</span>
    </div>
  ) : null;
  const summaryDiv =
    summary !== null ? (
      <div className="text-center m-4 capitalize font-semibold text-lg">
        <span className="text-brand-blue m-2">
          <FormattedMessage id="Form.Product_summary" />:
        </span>{" "}
        {summary}
      </div>
    ) : null;

  const handleInputChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
    id: string,
    type?: string
  ) => {
    if (type === "array") {
      const currentValue: string[] | string = values[id];
      const newArray =
        currentValue === undefined
          ? []
          : typeof currentValue === "string"
          ? [currentValue]
          : currentValue;
      dispatch(
        allActions.formActions.addToValue(
          id,
          newArray,
          event.target.value,
          formName
        )
      );
    } else {
      dispatch(
        allActions.formActions.updateValue(id, event.target.value, formName)
      );
    }
  };

  const chooseInputElement = (
    obj: formInputInfo,
    register: any,
    errors: any,
    handlerFunction: (event: any, id: string, type?: string) => void,
    index: number,
    divider: boolean
  ) => {
    const border = divider ? "border-t-2 border-fuchsia-600 pt-8" : "";
    const val = values[obj.id];
    const value = typeof val === "string" ? val : "";
    const valArr = Array.isArray(val) ? val : [];
    const repeatable = obj.repeatable ? true : false;
    const repeatableHeader = obj.repeatable ? obj.repeatable.header : null;
    const repeatableLast = obj.repeatable
      ? obj.repeatable.last
        ? {
            key: obj.repeatable.amount,
            last: true,
            amount: Number(values[obj.repeatable.amount]) as number,
          }
        : {
            key: obj.repeatable.amount,
            last: false,
            amount: Number(values[obj.repeatable.amount]) as number,
          }
      : { key: "", last: false, amount: 1 };
    const link = obj.link ? obj.link : false;
    const reg = obj.required
      ? returnRequiredRule(obj.required, register, values)
      : null;
    if (obj.type === "dropdown") {
      return (
        <FormSelect
          question={obj.question}
          key={obj.id}
          id={obj.id}
          value={value}
          options={obj.options}
          handleInputChange={handlerFunction}
          divider={border}
          register={reg}
          errors={errors}
          link={link}
          repeatableHeader={repeatableHeader}
          repeatableKey={repeatableLast}
          cursiveLabel={obj.description || null}
          validate={obj.required ? validate : null}
          index={index}
        />
      );
    } else if (obj.type === "textarea") {
      return (
        <FormTextArea
          question={obj.question}
          key={obj.id}
          id={obj.id}
          value={value}
          handleInputChange={handlerFunction}
          divider={border}
          register={reg}
          errors={errors}
          link={link}
          repeatableHeader={repeatableHeader}
          repeatableKey={repeatableLast}
          cursiveLabel={obj.description || null}
          validate={obj.required ? validate : null}
          index={index}
        />
      );
    } else if (obj.type === "radio" || obj.type === "product") {
      return (
        <FormRadio
          question={obj.question}
          key={obj.id}
          id={obj.id}
          options={obj.options}
          handleInputChange={handlerFunction}
          divider={border}
          register={reg}
          errors={errors}
          link={link}
          repeatableHeader={repeatableHeader}
          repeatableKey={repeatableLast}
          checked={value}
          cursiveLabel={obj.description || null}
          validate={obj.required ? validate : null}
          index={index}
        />
      );
    } else if (obj.type === "checkbox") {
      return (
        <FormCheckBox
          question={obj.question}
          key={obj.id}
          id={obj.id}
          options={obj.options}
          handleInputChange={handlerFunction}
          divider={border}
          register={reg}
          errors={errors}
          link={link}
          repeatableHeader={repeatableHeader}
          repeatableKey={repeatableLast}
          checked={valArr}
          cursiveLabel={obj.description || null}
          validate={obj.required ? validate : null}
          index={index}
        />
      );
    } else if (obj.type === "accept") {
      return (
        <FormCheckBox
          divStyle="flex flex-row-reverse justify-end items-center text-xs xl:w-11/12 xl:m-auto xl:text-base"
          question={obj.question}
          key={obj.id}
          id={obj.id}
          options={obj.options}
          handleInputChange={handlerFunction}
          divider={border}
          register={reg}
          errors={errors}
          link={link}
          repeatableHeader={repeatableHeader}
          repeatableKey={repeatableLast}
          checked={valArr}
          cursiveLabel={obj.description || null}
          validate={obj.required ? validate : null}
          index={index}
        />
      );
    } else {
      return (
        <FormInput
          question={obj.question}
          key={obj.id}
          id={obj.id}
          value={value}
          type={obj.type}
          handleInputChange={handlerFunction}
          divider={border}
          repeatable={repeatable}
          register={reg}
          errors={errors}
          link={link}
          repeatableHeader={repeatableHeader}
          repeatableKey={repeatableLast}
          cursiveLabel={obj.description || null}
          validate={obj.required ? validate : null}
          index={index}
        />
      );
    }
  };

  function validate(id: string) {
    trigger(id);
  }

  useEffect(() => {
    focusOnErrors(errors);
  }, [errors]);

  return (
    <>
      <div className="overflow-y-auto overflow-x-hidden scroller">
        <form
          className={`${formStyle}`}
          onSubmit={handleSubmit(formAction)}
          ref={formRef}
        >
          {titleDiv}
          {descriptionDiv}

          {sortByGroup(pageList).map((inputObject, index) => {
            const divider = Number(inputObject.group) !== Number(prevGroup);
            prevGroup = inputObject.group;
            return chooseInputElement(
              inputObject,
              register,
              errors,
              handleInputChange,
              index,
              divider
            );
          })}

          {messageDiv}
          {summaryDiv}
        </form>
      </div>
      <div></div>
    </>
  );
};

export default SingleModification;
