import React from "react";
import { useSelector } from "react-redux";
import { Router, Route, Switch } from "react-router-dom";
import history from "../history/history";
import { FormPage } from "../pages/FormPage";
import ModificationPage from "../pages/ModificationPage";
import { RootState } from "../reducers/combineReducer";
import { FormState } from "../types/types";

export const Navigation: React.FC = () => {
  const state: FormState = useSelector(
    (state: RootState) => state["formState"]
  );

  return (
    <Router history={history}>
      <div
        className="w-screen h-screen"
        style={{ backgroundColor: state.styling.backgroundColor || "white" }}
      >
        <Switch>
          <Route
            exact
            path="/"
            component={() => {
              window.location.href = "https://aidc.fi/#cme";
              return null;
            }}
          />
          <Route exact path="/:domain?/:id" render={() => <FormPage />} />
          <Route
            path="/:domain?/:operation/:id/:token"
            render={() => <ModificationPage />}
          />
        </Switch>
      </div>
    </Router>
  );
};

export default Navigation;

