import React from "react";
import { RequiredAsterisk } from "./RequiredAsterisk";

interface Props {
  labelStyle?: string;
  question: string;
  register?: any;
}

export const Label: React.FC<Props> = ({ labelStyle, question, register }) => {
  const labelStyles = labelStyle
    ? labelStyle
    : "mb-2 ml-2 capitalize font-bold text-lg text-brand-dark_blue";
  return (
    <span className={`${labelStyles}`}>
      {question}
      <RequiredAsterisk register={register} />
    </span>
  );
};
