import React from "react";

interface ProgressProps {
  steps: number[];
  current: number;
  allowed: number;
  setPage: (e: any, page: number) => void;
}

export const ProgressBar: React.FC<ProgressProps> = ({
  steps,
  current,
  allowed,
  setPage,
}) => {
  return (
    <div
      className={`xl:h-5 h-3 bg-gray-400 w-full flex justify-between rounded-b shadow-xl`}
    >
      {steps.map((step, index) => {
        const ballColor = step < current + 1 ? "bg-blue-300" : "bg-blue-200";
        const fillerColor = step < current + 1 ? "bg-blue-200" : "bg-gray-200";
        const highLight =
          step === current
            ? "border-brand-dark_blue text-brand-dark_blue"
            : "border-brand-blue text-brand-blue";
        const cursor =
          step > allowed + 1
            ? "cursor-default"
            : "cursor-pointer hover:bg-brand-white";
        return (
          <div
            key={step}
            className={`relative h-full w-full text-center ${fillerColor} rounded-b`}
          >
            <button
              className={`${ballColor} rounded-full xl:h-10 xl:w-10 xl:-m-2 h-6 w-6 border absolute inset-y-1 right-0 -m-1 z-30 ${highLight} text-center ${cursor}`}
              onClick={(e) => setPage(e, step)}
              disabled={step > allowed + 1}
            >
              {step}
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default ProgressBar;
