import {
  formInputInfo,
  FormState,
  Summary,
  modifyType,
  AnswerObject,
} from "../types/types";
import { LoopReducer, loop, Loop, Cmd } from "redux-loop";
import formService, {
  CancelFormResponse,
  GetFormResponse,
} from "../services/forms";
import {
  getType,
  createAsyncAction,
  ActionType,
  createAction,
} from "typesafe-actions";
import registerService, { ReservationResponse } from "../services/register";
import { registrationAsync } from "./registerReducer";
import { defaultLogoUrl, defaultBg } from "../services/config";
import { AppState } from "./combineReducer";
import { cancelRegistrationAsync } from "./reservationReducer";
import { MessageKey } from "../components/modals/messages";

const initialState: FormState = {
  input: {},
  items: {},
  summary: {
    subEventId: null,
    linkUri: null,
    answers: {},
  },
  page: {
    currentPage: null,
    highestVisited: null,
  },
  info: {
    eventName: "",
    eventDescription: "",
    subEventId: "",
    registrationEnded: false,
    registrationFull: false,
    registrationNotYetOpen: false,
    showRegistration: false,
    maintenanceMode: false,
    expiredModificationToken: false,
    queue: false,
    message: null,
    linkUri: null,
  },
  styling: {
    backgroundColor: "",
    logoUrl: "",
    showSubmit: true,
  },
  initLoading: true,
  pageHeader: "",
  pageDescription: "",
  pageThankYouHeader: "",
  pageThankYouText: "",
  pageRegistrationFullHeader: "",
  pageRegistrationFullText: "",
  pageRegistrationNotYetOpenHeader: "",
  pageRegistrationNotYetOpenText: "",
  pageRegistrationEndedHeader: "",
  pageRegistrationEndedText: "",
};

export const getFormAsync = createAsyncAction(
  "START_FORM_FETCH",
  "FETCH_COMPLETE",
  "FETCH_FAIL"
)<string, { data: GetFormResponse; status: number }, Error>();

export const getModificationFormAsync = createAsyncAction(
  "START_MODIFICATION_FORM_FETCH",
  "MODIFICATION_FETCH_COMPLETE",
  "MODIFICATION_FETCH_FAIL"
)<
  { form: string; modificationToken: string },
  { data: any; status: number },
  Error
>();

export const cancelFormAsync = createAsyncAction(
  "START_CANCEL_FORM_FETCH",
  "CANCEL_FETCH_COMPLETE",
  "CANCEL_FETCH_FAIL"
)<
  { form: string; cancelToken: string },
  { data: CancelFormResponse; status: number },
  Error
>();

export const reserveAsync = createAsyncAction(
  "START_RESERVE_X",
  "RESERVE_X_COMPLETE",
  "RESERVE_X_FAIL"
)<
  {
    amount: number;
    subEventId: string;
    key: string;
    value: string;
    form: string;
  },
  { data: ReservationResponse; status: number },
  Error
>();

export const unreserveAsync = createAsyncAction(
  "START_UNRESERVE",
  "UNRESERVE_COMPLETE",
  "UNRESERVE_FAIL"
)<
  {
    deleted: string[];
    subEventId: string;
    key: string;
    value: string;
    form: string;
  },
  { data: any; status: number },
  Error
>();

const updateValue = createAction("UPDATEVALUE")<{
  form: string;
  id: string;
  value: string;
}>();
export const reserveRollBackValue = createAction("RESERVEROLLBACK")<{
  form: string;
  id: string;
  value: string;
  message: MessageKey;
}>();
export const reserveSuccess = createAction("RESERVE_SUCCESS")<
  { data: ReservationResponse; status: number },
  { form: string; id: string; value: string; message: string }
>();
const updateDateValue = createAction("UPDATEDATEVALUE")<{
  form: string;
  id: string;
  value: Date;
}>();
const addToValue = createAction("ADDTOVALUE")<{
  form: string;
  id: string;
  value: string[];
}>();
const removeKey = createAction("REMOVEKEY")<{ form: string; id: string }>();
const removeMessage = createAction("REMOVEMESSAGE")<{}>();
const setPage = createAction("SETPAGE")<{ page: number; highest: number }>();
const createRegistrationSummary = createAction("CREATEREGISRTATIONSUMMARY")<{
  data: Summary;
  form: string;
}>();

type Action =
  | ActionType<typeof getFormAsync>
  | ActionType<typeof reserveAsync>
  | ActionType<typeof unreserveAsync>
  | ActionType<typeof registrationAsync>
  | ActionType<typeof cancelRegistrationAsync>
  | ActionType<typeof getModificationFormAsync>
  | ActionType<typeof cancelFormAsync>
  | ActionType<typeof updateValue>
  | ActionType<typeof updateDateValue>
  | ActionType<typeof addToValue>
  | ActionType<typeof removeKey>
  | ActionType<typeof removeMessage>
  | ActionType<typeof reserveRollBackValue>
  | ActionType<typeof reserveSuccess>
  | ActionType<typeof setPage>
  | ActionType<typeof createRegistrationSummary>;

export const formReducer: LoopReducer<FormState, Action> = (
  state: FormState = initialState,
  action: Action
): FormState | Loop<FormState> => {
  switch (action.type) {
    case getType(updateValue):
      return {
        ...state,
        input: {
          ...state.input,
          [action.payload.form]: {
            ...state.input[action.payload.form],
            [action.payload.id]: action.payload.value,
          },
        },
      };
    case getType(reserveRollBackValue):
      return {
        ...state,
        initLoading: false,
        input: {
          ...state.input,
          [action.payload.form]: {
            ...state.input[action.payload.form],
            [action.payload.id]: action.payload.value,
          },
        },
        info: {
          ...state.info,
          message: action.payload.message,
        },
      };
    case getType(updateDateValue):
      return {
        ...state,
        input: {
          ...state.input,
          [action.payload.form]: {
            ...state.input[action.payload.form],
            [action.payload.id]: action.payload.value.toISOString(),
          },
        },
      };
    case getType(addToValue):
      return {
        ...state,
        input: {
          ...state.input,
          [action.payload.form]: {
            ...state.input[action.payload.form],
            [action.payload.id]: action.payload.value,
          },
        },
      };

    case getType(removeKey):
      if (state.input[action.payload.form][action.payload.id]) {
        const { [action.payload.id]: remove, ...rest } =
          state.input[action.payload.form];
        return {
          ...state,
          input: {
            ...state.input,
            [action.payload.form]: rest,
          },
        };
      } else {
        return state;
      }

    case getType(removeMessage):
      return {
        ...state,
        info: {
          ...state.info,
          message: null,
        },
      };

    case getType(setPage):
      return {
        ...state,
        page: {
          ...state.page,
          currentPage: action.payload.page,
          highestVisited: action.payload.highest,
        },
      };

    //get form
    case getType(getFormAsync.request):
      return loop(
        {
          ...state,
          initLoading: true,
        },
        Cmd.run(formService.getForm, {
          successActionCreator: getFormAsync.success,
          failActionCreator: getFormAsync.failure,
          args: [action.payload],
        })
      );
    case getType(getFormAsync.success):
      if (action.payload.status === 200) {
        //uncomment this 211 and line 227, 231 to be action.payload.data.linkuri, when we get correct response
        //const formName = 'aidc/2adjv'
        const formItems = action.payload.data.registrationQuestions;
        const pages: number[] =
          formItems.length > 0
            ? Array.from(new Set(formItems.map((item) => item.page)))
            : [0];
        return {
          ...state,
          initLoading: false,
          pageHeader: action.payload.data.pageHeader,
          pageDescription: action.payload.data.pageDescription,
          pageThankYouHeader: action.payload.data.pageThankYouHeader,
          pageThankYouText: action.payload.data.pageThankYouText,
          pageRegistrationFullHeader:
            action.payload.data.pageRegistrationFullHeader,
          pageRegistrationFullText:
            action.payload.data.pageRegistrationFullText,
          pageRegistrationNotYetOpenHeader:
            action.payload.data.pageRegistrationNotYetOpenHeader,
          pageRegistrationNotYetOpenText:
            action.payload.data.pageRegistrationNotYetOpenText,
          pageRegistrationEndedHeader:
            action.payload.data.pageRegistrationEndedHeader,
          pageRegistrationEndedText:
            action.payload.data.pageRegistrationEndedText,
          items: {
            ...state.items,
            [action.payload.data.linkUri]:
              action.payload.data.registrationQuestions,
          },
          input: {
            ...state.input,
            [action.payload.data.linkUri]: {},
          },
          page: {
            currentPage: pages[0],
            highestVisited: pages[0],
          },
          info: {
            ...state.info,
            eventName: action.payload.data.pageHeader,
            eventDescription: action.payload.data.pageDescription,
            subEventId: action.payload.data.subEventId,
            registrationEnded: action.payload.data.status.registrationEnded,
            registrationFull: action.payload.data.status.registrationFull,
            registrationNotYetOpen:
              action.payload.data.status.registrationNotYetOpen,
            showRegistration:
              action.payload.data.status.normal ||
              action.payload.data.status.que
                ? true
                : false,
            maintenanceMode: action.payload.data.status.maintenanceMode,
            queue: action.payload.data.status.que,
            message: null,
            linkUri: action.payload.data.linkUri,
          },
          styling: {
            ...state.styling,
            backgroundColor: action.payload.data.pageStyling.backgroundColor,
            logoUrl: action.payload.data.pageStyling.logoUrl,
            showSubmit: action.payload.data.pageStyling.showSubmit===false ? action.payload.data.pageStyling.showSubmit : true,
          },
        };
      } else if (action.payload.status === 206) {
        return {
          ...state,
          initLoading: false,
          info: {
            ...state.info,
            maintenanceMode: action.payload.data.status.maintenanceMode,
            maintenanceModeEndTime: action.payload.data.status.maintenanceMode
              ? action.payload.data.maintenanceModeEndTime
              : null,
            message: "ONGOING MAINTENANCE",
          },
          styling: {
            ...state.styling,
            backgroundColor: action.payload.data.pageStyling
              ? action.payload.data.pageStyling.backgroundColor
              : defaultBg,
            logoUrl: action.payload.data.pageStyling
              ? action.payload.data.pageStyling.logoUrl
              : defaultLogoUrl,
          },
        };
      } else {
        return { ...state, initLoading: false };
      }
    case getType(getFormAsync.failure):
      return {
        ...state,
        initLoading: false,
      };

    //get single-person-modification-form
    case getType(getModificationFormAsync.request):
      return loop(
        {
          ...state,
          initLoading: true,
        },
        Cmd.run(formService.getModificationForm, {
          successActionCreator: getModificationFormAsync.success,
          failActionCreator: getModificationFormAsync.failure,
          args: [action.payload.form, action.payload.modificationToken],
        })
      );
    case getType(getModificationFormAsync.success):
      if (action.payload.status === 200) {
        const flattenObject = (obj: object) => {
          const flattened = {};
          const questionIds: string[] =
            action.payload.data.registrationQuestions.map((q) => q.id);
          Object.keys(obj).forEach((key) => {
            if (
              typeof obj[key] === "object" &&
              obj[key] !== null &&
              !Array.isArray(obj[key])
            ) {
              Object.assign(flattened, flattenObject(obj[key]));
            } else {
              if (questionIds.includes(key)) {
                flattened[key] = obj[key];
              }
            }
          });
          return flattened;
        };

        const inputs = flattenObject(
          action.payload.data.registerQuestionAnswers.participants
        );

        return {
          ...state,
          initLoading: false,
          pageHeader: action.payload.data.pageHeader,
          pageDescription: action.payload.data.pageDescription,
          pageThankYouHeader: action.payload.data.pageThankYouHeader,
          pageThankYouText: action.payload.data.pageThankYouText,
          pageRegistrationFullHeader:
            action.payload.data.pageRegistrationFullHeader,
          pageRegistrationFullText:
            action.payload.data.pageRegistrationFullText,
          pageRegistrationNotYetOpenHeader:
            action.payload.data.pageRegistrationNotYetOpenHeader,
          pageRegistrationNotYetOpenText:
            action.payload.data.pageRegistrationNotYetOpenText,
          pageRegistrationEndedHeader:
            action.payload.data.pageRegistrationEndedHeader,
          pageRegistrationEndedText:
            action.payload.data.pageRegistrationEndedText,
          items: {
            ...state.items,
            [action.payload.data.linkUri]:
              action.payload.data.registrationQuestions,
          },
          input: {
            ...state.input,
            [action.payload.data.linkUri]: inputs,
          },
          page: {
            currentPage: 1,
            highestVisited: 1,
          },
          summary: {
            ...state.summary,
            subEventId: action.payload.data.registerQuestionAnswers.subEventId,
            linkUri: action.payload.data.linkUri,
          },
          info: {
            ...state.info,
            eventName: action.payload.data.pageHeader,
            eventDescription: action.payload.data.pageDescription,
            subEventId: action.payload.data.subEventId,
            showRegistration: true,
            //maintenanceMode: action.payload.data.status.maintenanceMode,
            message: null,
            linkUri: action.payload.data.linkUri,
          },
          styling: {
            ...state.styling,
            backgroundColor: action.payload.data.pageStyling.backgroundColor,
            logoUrl: action.payload.data.pageStyling.logoUrl,
          },
        };
      } else if (action.payload.status === 206) {
        return {
          ...state,
          initLoading: false,
          info: {
            ...state.info,
            maintenanceMode: action.payload.data.status.maintenanceMode,
            maintenanceModeEndTime: action.payload.data.status.maintenanceMode
              ? action.payload.data.maintenanceModeEndTime
              : null,
            expiredModificationToken: action.payload.data.status.expiredToken,
            message: action.payload.data.status.maintenanceMode
              ? "ONGOING MAINTENANCE"
              : action.payload.data.status.expiredToken
              ? "EXPIRED MODIFY TOKEN"
              : null,
          },
          styling: {
            ...state.styling,
            backgroundColor: action.payload.data.pageStyling
              ? action.payload.data.pageStyling.backgroundColor
              : defaultBg,
            logoUrl: action.payload.data.pageStyling
              ? action.payload.data.pageStyling.logoUrl
              : defaultLogoUrl,
          },
        };
      } else {
        return { ...state, initLoading: false };
      }
    case getType(getModificationFormAsync.failure):
      return {
        ...state,
        initLoading: false,
      };

    //get single-person-cancel-form
    case getType(cancelFormAsync.request):
      return loop(
        {
          ...state,
          initLoading: true,
        },
        Cmd.run(formService.getCancelForm, {
          successActionCreator: cancelFormAsync.success,
          failActionCreator: cancelFormAsync.failure,
          args: [action.payload.form, action.payload.cancelToken],
        })
      );

    case getType(cancelFormAsync.success):
      if (action.payload.status === 200) {
        const { answerSummary, pageStyling, subEventId, personIsInGroup } =
          action.payload.data;
        return {
          ...state,
          initLoading: false,
          info: {
            ...state.info,
            subEventId,
            showRegistration: true,
            personIsInGroup,
          },
          summary: { ...state.summary, overview: answerSummary },
          styling: {
            ...state.styling,
            backgroundColor: pageStyling
              ? pageStyling.backgroundColor
              : defaultBg,
            logoUrl: pageStyling ? pageStyling.logoUrl : defaultLogoUrl,
          },
        };
      }
      if (action.payload.status === 206) {
        return {
          ...state,
          initLoading: false,
          info: {
            ...state.info,
            maintenanceMode: action.payload.data.status.maintenanceMode,
            maintenanceModeEndTime: action.payload.data.status.maintenanceMode
              ? action.payload.data.maintenanceModeEndTime
              : null,
            expiredModificationToken: action.payload.data.status.expiredToken,
            message: action.payload.data.status.maintenanceMode
              ? "ONGOING MAINTENANCE"
              : action.payload.data.status.expiredToken
              ? "EXPIRED CANCEL TOKEN"
              : null,
          },
          styling: {
            ...state.styling,
            backgroundColor: action.payload.data.pageStyling
              ? action.payload.data.pageStyling.backgroundColor
              : defaultBg,
            logoUrl: action.payload.data.pageStyling
              ? action.payload.data.pageStyling.logoUrl
              : defaultLogoUrl,
          },
        };
      }
      return {
        ...state,
        initLoading: false,
      };
    case getType(cancelFormAsync.failure):
      return {
        ...state,
        initLoading: false,
      };

    //reserve
    case getType(reserveAsync.request):
      const rollBack = {
        key: action.payload.key,
        value: (
          Number(state.input[action.payload.form][action.payload.key]) -
          Number(action.payload.amount)
        ).toString(),
        form: action.payload.form,
      };
      return loop(
        {
          ...state,
          initLoading: true,
        },
        Cmd.run(registerService.reserve, {
          successActionCreator: (result: {
            data: ReservationResponse;
            status: number;
          }) =>
            reserveSuccess(result, {
              form: rollBack.form,
              id: rollBack.key,
              value: rollBack.value,
              message: null,
            }),
          failActionCreator: () =>
            reserveRollBackValue({
              form: rollBack.form,
              id: rollBack.key,
              value: rollBack.value,
              message: "RESERVATION FAIL" as const,
            }),
          args: [action.payload.amount, action.payload.subEventId],
        })
      );
    case getType(reserveSuccess):
      if (action.payload.status === 200) {
        if (action.payload.data.state.quedRegistation) {
          return {
            ...state,
            initLoading: false,
            info: {
              ...state.info,
              message: "REGISTRATION TO QUEUE",
            },
          };
        } else {
          return {
            ...state,
            info: {
              ...state.info,
              message: null,
            },
            initLoading: false,
          };
        }
      } else if (action.payload.status === 206) {
        return {
          ...state,
          initLoading: false,
          info: {
            ...state.info,
            message: action.payload.data.status.eventFullNoQue
              ? "EVENT FULL, NO REGISTRATION ALLOWED"
              : action.payload.data.status.oversizedGroup
              ? "TOO LARGE GROUP"
              : "REGISTRATION TO QUEUE",
          },
          input: {
            ...state.input,
            [action.meta.form]: {
              ...state.input[action.meta.form],
              [action.meta.id]: action.meta.value,
            },
          },
        };
      } else {
        return {
          ...state,
          initLoading: false,
          info: {
            ...state.info,
            message: "RESERVATION FAIL",
          },
        };
      }

    case getType(reserveAsync.failure):
      return {
        ...state,
        initLoading: false,
        info: {
          ...state.info,
          message: "RESERVATION FAIL",
        },
      };

    //unreserve
    case getType(unreserveAsync.request):
      const unReserveRollBack = {
        key: action.payload.key,
        value: (
          Number(state.input[action.payload.form][action.payload.key]) +
          action.payload.deleted.length
        ).toString(),
        form: action.payload.form,
      };
      return loop(
        {
          ...state,
          initLoading: true,
        },
        Cmd.run(registerService.unreserve, {
          successActionCreator: unreserveAsync.success,
          failActionCreator: () =>
            reserveRollBackValue({
              form: unReserveRollBack.form,
              id: unReserveRollBack.key,
              value: unReserveRollBack.value,
              message: "UNRESERVE_FAIL" as const,
            }),
          args: [
            {
              uuids: action.payload.deleted,
              subEventId: action.payload.subEventId,
            },
          ],
        })
      );

    case getType(unreserveAsync.success):
      if (action.payload.status === 200) {
        return {
          ...state,
          info: {
            ...state.info,
            message: null,
          },
          initLoading: false,
        };
      } else {
        return {
          ...state,
          initLoading: false,
          info: {
            ...state.info,
            message: "UNRESERVE_FAIL",
          },
        };
      }

    case getType(unreserveAsync.failure):
      return {
        ...state,
        initLoading: false,
        info: {
          ...state.info,
          message: "RESERVATION FAIL",
        },
      };

    //summary tilaan!
    case getType(createRegistrationSummary):
      const overViewParticipants = Object.keys(action.payload.data.answers).map(
        (key) => action.payload.data.answers[key].filter((a) => a.person)
      );

      const firstAnswerUuid = Object.keys(action.payload.data.answers)[0];
      const overViewInfo = action.payload.data.answers[firstAnswerUuid]
        .filter((a) => !a.person)
        .map((i) => i);

      const overview = {
        info: overViewInfo,
        participants: overViewParticipants,
      };

      const modifyType: modifyType =
        Object.keys(action.payload.data.answers).length > 1
          ? "group"
          : "single";

      const summary = {
        subEventId: action.payload.data.subEventId,
        linkUri: state.info.linkUri,
        answers: action.payload.data.answers,
        modifyType,
        overview,
      };

      return loop(
        {
          ...state,
          initLoading: true,
          summary,
        },
        Cmd.action({ type: "START_REGISTRATION", payload: summary })
      );

    //rekisteröinti onnistuu
    case getType(registrationAsync.success):
      if (action.payload.status === 200) {
        return {
          ...state,
          initLoading: false,
          info: {
            ...state.info,
            showRegistration: false,
          },
        };
      }
      return state;

    case getType(registrationAsync.failure):
      return {
        ...state,
        initLoading: false,
        info: {
          ...state.info,
          message: "REGISTRATION FAIL",
        },
      };

    case getType(cancelRegistrationAsync.request):
      return { ...state, initLoading: true };

    case getType(cancelRegistrationAsync.success):
      if (action.payload.status === 200) {
        return {
          ...state,
          initLoading: false,
          info: {
            ...state.info,
            message: "CANCEL REGISTRATION SUCCESS",
          },
        };
      }
      return state;

    case getType(cancelRegistrationAsync.failure):
      return {
        ...state,
        initLoading: false,
        info: {
          ...state.info,
          message: "CANCEL REGISTRATION FAIL",
        },
      };

    default:
      return state;
  }
};

export type OverView = {
  info: AnswerObject[];
  participants: AnswerObject[][];
};

export type FormPageData = {
  items: formInputInfo[];
  input: { [key: string]: string | string[] };
  logoUrl: string;
  subEventId: string;
  initLoading: boolean;
  showSubmit: boolean;
  message: MessageKey;
  pageHeader: string;
  pageDescription: string;
  pageThankYouHeader: string;
  pageThankYouText: string;
  registrationFull: boolean;
  registrationEnded: boolean;
  pageRegistrationEndedText: string;
  pageRegistrationEndedHeader: string;
  pageRegistrationFullHeader: string;
  pageRegistrationFullText: string;
  maintenanceMode: boolean;
  overview: OverView;
  maintenanceModeEndTime: string;
  pageRegistrationNotYetOpenHeader: string;
  pageRegistrationNotYetOpenText: string;
  showRegistration: boolean;
  uuids: string[];
  expiredAmount: number;
  registered: boolean;
};
export function selectFormPageData(
  state: AppState,
  formName: string
): FormPageData {
  const items = state.formState.items[formName]
    ? state.formState.items[formName]
    : [];
  const input = state.formState.input[formName]
    ? state.formState.input[formName]
    : {};
  const logoUrl = state.formState.styling.logoUrl;
  const subEventId = state.formState.info.subEventId;
  const initLoading = state.formState.initLoading;
  const showSubmit = state.formState.styling.showSubmit;
  const message = state.formState.info.message;
  const pageHeader = state.formState.pageHeader;
  const pageDescription = state.formState.pageDescription;
  const pageThankYouHeader = state.formState.pageThankYouHeader;
  const pageThankYouText = state.formState.pageThankYouText;
  const registrationFull = state.formState.info.registrationFull;
  const registrationEnded = state.formState.info.registrationEnded;
  const pageRegistrationFullHeader = state.formState.pageRegistrationFullHeader;
  const pageRegistrationFullText = state.formState.pageRegistrationFullText;
  const pageRegistrationEndedHeader =
    state.formState.pageRegistrationEndedHeader;
  const pageRegistrationEndedText = state.formState.pageRegistrationEndedText;
  const maintenanceMode = state.formState.info.maintenanceMode;
  const overview = state.formState.summary.overview;
  const maintenanceModeEndTime = state.formState.info.maintenanceModeEndTime;
  const pageRegistrationNotYetOpenHeader =
    state.formState.pageRegistrationNotYetOpenHeader;
  const pageRegistrationNotYetOpenText =
    state.formState.pageRegistrationNotYetOpenText;
  const showRegistration = state.formState.info.showRegistration;
  const uuids = state.reservationState.uuids;
  const expiredAmount = state.reservationState.expiredAmount;
  const registered = state.registerState.registered;
  return {
    items,
    input,
    logoUrl,
    subEventId,
    initLoading,
    showSubmit,
    message,
    pageDescription,
    pageHeader,
    pageRegistrationFullText,
    pageRegistrationFullHeader,
    pageRegistrationNotYetOpenText,
    pageRegistrationEndedHeader,
    pageRegistrationEndedText,
    registrationEnded,
    pageThankYouHeader,
    pageThankYouText,
    pageRegistrationNotYetOpenHeader,
    overview,
    registrationFull,
    maintenanceMode,
    maintenanceModeEndTime,
    showRegistration,
    uuids,
    expiredAmount,
    registered,
  };
}

export type ModificationPageData = FormPageData & {
  cancelIndex: number;
  personIsInGroup: boolean;
};
export function selectModificationPageData(
  state: AppState,
  formName: string
): ModificationPageData {
  const items = state.formState.items[formName]
    ? state.formState.items[formName]
    : [];
  const input = state.formState.input[formName]
    ? state.formState.input[formName]
    : {};
  const registered = state.registerState.registered;
  const logoUrl = state.formState.styling.logoUrl;
  const subEventId = state.formState.info.subEventId;
  const initLoading = state.formState.initLoading;
  const showSubmit = state.formState.styling.showSubmit;
  const message = state.formState.info.message;
  const pageHeader = state.formState.pageHeader;
  const registrationEnded = state.formState.info.registrationEnded;
  const pageRegistrationEndedHeader =
    state.formState.pageRegistrationEndedHeader;
  const pageRegistrationEndedText = state.formState.pageRegistrationEndedText;
  const pageDescription = state.formState.pageDescription;
  const pageThankYouHeader = state.formState.pageThankYouHeader;
  const pageThankYouText = state.formState.pageThankYouText;
  const registrationFull = state.formState.info.registrationFull;
  const pageRegistrationFullHeader = state.formState.pageRegistrationFullHeader;
  const pageRegistrationFullText = state.formState.pageRegistrationFullText;
  const maintenanceMode = state.formState.info.maintenanceMode;
  const overview = state.formState.summary.overview;
  const maintenanceModeEndTime = state.formState.info.maintenanceModeEndTime;
  const pageRegistrationNotYetOpenHeader =
    state.formState.pageRegistrationNotYetOpenHeader;
  const pageRegistrationNotYetOpenText =
    state.formState.pageRegistrationNotYetOpenText;
  const showRegistration = state.formState.info.showRegistration;
  const uuids = state.reservationState.uuids;
  const expiredAmount = state.reservationState.expiredAmount;
  const cancelIndex = state.formState.info.cancelIndex
    ? state.formState.info.cancelIndex
    : 0;
  const personIsInGroup = state.formState.info.personIsInGroup ?? false;
  return {
    items,
    input,
    logoUrl,
    registered,
    pageRegistrationEndedHeader,
    pageRegistrationEndedText,
    registrationEnded,
    subEventId,
    initLoading,
    showSubmit,
    message,
    pageDescription,
    pageHeader,
    pageRegistrationFullText,
    pageRegistrationFullHeader,
    pageRegistrationNotYetOpenText,
    pageThankYouHeader,
    pageThankYouText,
    pageRegistrationNotYetOpenHeader,
    overview,
    registrationFull,
    maintenanceMode,
    maintenanceModeEndTime,
    showRegistration,
    uuids,
    expiredAmount,
    cancelIndex,
    personIsInGroup,
  };
}

export type FormValues = {
  allItems: formInputInfo[];
  items: formInputInfo[];
  values: { [key: string]: string | string[] };
  repeatableLength: number;
  pages: number[];
  currentPage: number;
  highestVisited: number;
  maxPage: number;
};
export function selectFormItemsAndValues(
  state: AppState,
  formName: string
): FormValues {
  const values = state.formState.input[formName]
    ? state.formState.input[formName]
    : {};
  const formItems: formInputInfo[] = state.formState.items[formName]
    ? state.formState.items[formName]
    : [];
  const repeatableAdded = modifyListDependingOnStateAndRepeats(
    values,
    formItems,
    returnRepeatableGroups(formItems.filter((q) => q.repeatable))
  );
  const pages =
    formItems.length > 0
      ? Array.from(new Set(formItems.map((item) => item.page)))
      : [0];
  const items = filterList(repeatableAdded, values);
  const currentPage = state.formState.page.currentPage
    ? state.formState.page.currentPage
    : pages[0];
  const highestVisited = state.formState.page.highestVisited;
  const maxPage = pages[pages.length - 1];
  return {
    values,
    items,
    repeatableLength: repeatableAdded.length,
    allItems: formItems,
    pages,
    currentPage,
    highestVisited,
    maxPage,
  };
}

function returnRepeatableGroups(
  listOfRepeatableQuestions: formInputInfo[]
): formInputInfo[][] {
  const sortedList = listOfRepeatableQuestions.sort(
    (a, b) =>
      a.page - b.page ||
      a.repeatable.key.localeCompare(b.repeatable.key) ||
      a.repeatable.value.localeCompare(b.repeatable.value) ||
      a.repeatable.amount.localeCompare(b.repeatable.amount)
  );
  const allGroups: formInputInfo[][] = [];
  let uniqueGroup: formInputInfo[] = [];
  let prevObj = {
    after: "",
    amount: "",
    key: "",
    value: "",
  };
  for (const question of sortedList) {
    const currObj = {
      after: question.repeatable.after,
      amount: question.repeatable.amount,
      key: question.repeatable.key,
      value: question.repeatable.value,
    };
    if (checkIfObjectMatches(prevObj, currObj)) {
      uniqueGroup.push(question);
    } else {
      if (uniqueGroup.length !== 0) {
        allGroups.push(changeLastElement(uniqueGroup));
      }
      uniqueGroup = [question];
    }
    prevObj = currObj;
  }
  if (uniqueGroup.length > 0) {
    allGroups.push(changeLastElement(uniqueGroup));
  }
  return allGroups;
}

function checkIfObjectMatches(
  obj: { after: string; amount: string; key: string; value: string },
  obj2: { after: string; amount: string; key: string; value: string }
) {
  return (
    obj.amount === obj2.amount &&
    obj.key === obj2.key &&
    obj.value === obj2.value
  );
}

function changeLastElement(array: formInputInfo[]) {
  const copyOfLastElement = { ...array[array.length - 1] };
  const changedLastElement = {
    ...copyOfLastElement,
    repeatable: { ...copyOfLastElement.repeatable, last: true },
  };
  array.splice(array.length - 1, 1, changedLastElement);
  return array;
}

function modifyListDependingOnStateAndRepeats(
  formState: { [key: string]: string | string[] },
  allQuestions: formInputInfo[],
  repeatableGroups: formInputInfo[][]
) {
  const copyOfQuestions = [...allQuestions];
  for (const group of repeatableGroups) {
    if (
      group[0].repeatable.key === "*" ||
      formState[group[0].repeatable.key] === group[0].repeatable.value
    ) {
      const lastItem = group.find((s) => s.repeatable.last);
      const lastItemIndex = copyOfQuestions.findIndex(
        (s) => s.id === lastItem.id
      );
      const groupAmount = group[0].repeatable.amount;
      const numberOfRepeats = !isNaN(Number(groupAmount))
        ? Number(groupAmount)
        : formState[groupAmount] === undefined || formState[groupAmount] === ""
        ? 0
        : Number(formState[groupAmount]) - 1;
      const repeated = Array(numberOfRepeats).fill(group);
      const flattened: formInputInfo[] = repeated.reduce(
        (acc, val) => acc.concat(val),
        []
      );
      let iterator = 2;
      let groupCounter = 0;
      let maxGroup = copyOfQuestions
        .map((item) => item.group)
        .reduce((a, b) => {
          return Math.max(a, b);
        });
      const result = flattened.map((item) => {
        groupCounter++;
        if (groupCounter > group.length) {
          iterator++;
          groupCounter = 1;
        }
        return {
          ...item,
          id: `${item.id}_${iterator}`,
          group: maxGroup + (iterator - 1),
          ...(item.hide && {
            hide: {
              ...item.hide,
              key: getCorrectKey(iterator, item.hide.key, allQuestions),
            },
          }),
        };
      });
      const afterIndex = allQuestions.findIndex(
        (item) => item.id === group[0].repeatable.after
      );
      copyOfQuestions[lastItemIndex] = {
        ...copyOfQuestions[lastItemIndex],
        repeatable: {
          ...copyOfQuestions[lastItemIndex].repeatable,
          last: true,
        },
      };
      copyOfQuestions.splice(afterIndex, 0, ...result);
    }
  }
  return copyOfQuestions;
}

function getCorrectKey(
  iterator: number,
  key: string,
  allQuestions: formInputInfo[]
) {
  const item = allQuestions.find((q) => q.id === key);
  if (item.repeatable) {
    return `${key}_${iterator}`;
  } else {
    return key;
  }
}

function filterList(
  list: formInputInfo[],
  formState: { [key: string]: string | string[] }
) {
  return list.filter((q) => shouldShow(q, formState));
}

function shouldShow(
  q: formInputInfo,
  formState: { [key: string]: string | string[] }
) {
  return q.show ? hasHidden(q, formState) : false;
}
function hasHidden(
  q: formInputInfo,
  formState: { [key: string]: string | string[] }
) {
  return q.hide ? hideTypeSelect(q, formState) : q;

  function hideTypeSelect(
    q: formInputInfo,
    formState: { [key: string]: string | string[] }
  ) {
    return q.hide.type.toLowerCase() === "show".toLowerCase()
      ? hiddenIsCorrectShow(q, formState)
      : hiddenIsCorrectHide(q, formState);
  }
}
function hiddenIsCorrectShow(
  q: formInputInfo,
  formState: { [key: string]: string | string[] }
) {
  if (Array.isArray(formState[q.hide.key])) {
    return formState[q.hide.key].includes(q.hide.value) ? q : false;
  }
  return q.hide.value === formState[q.hide.key] ? q : false;
}

function hiddenIsCorrectHide(
  q: formInputInfo,
  formState: { [key: string]: string | string[] }
) {
  if (Array.isArray(formState[q.hide.key])) {
    return !formState[q.hide.key].includes(q.hide.value) ? q : [];
  }
  return q.hide.value !== formState[q.hide.key] ? q : false;
}
