import React from "react";

interface MessageProps {
  messageHeader: string;
  message: string;
  additionalContent: boolean;
}

export const Message: React.FC<MessageProps> = ({
  message,
  messageHeader,
  additionalContent,
}) => {
  const roundedBottom = additionalContent ? null : "rounded-b";

  return (
    <div className="container">
      <div className={`flex flex-col bg-brand-white pt-4 ${roundedBottom}`}>
        <span className="text-center text-brand-dark_blue capitalize font-bold m-4">
          {messageHeader}
        </span>
        <span className="text-brand-dark_blue text-center mb-4">{message}</span>
      </div>
    </div>
  );
};

export default Message;
