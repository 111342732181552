import React from "react";
import { IconWrapper } from "../../utils/IconWrapper";
import { IoIosHelpCircleOutline } from "react-icons/io";

interface Props {
  mouseHoverTrigger: (e: React.MouseEvent) => void;
  overlayId?: string;
}

export const HelpIcon: React.FC<Props> = ({ mouseHoverTrigger, overlayId }) => {
  return (
    overlayId && (
      <IconWrapper styles={"self-center"}>
        <IoIosHelpCircleOutline
          onMouseEnter={(e) => mouseHoverTrigger(e)}
          onMouseLeave={(e) => mouseHoverTrigger(e)}
          size={30}
        ></IoIosHelpCircleOutline>
      </IconWrapper>
    )
  );
};
