import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers/combineReducer'

interface ToggleProps {
    id: string,
}

export const Toggle: React.FC<ToggleProps> = ({id, children}) => {
  const show = useSelector((state: RootState) => state['toggleState'][id]);
  return  show ? <React.Fragment>{children}</React.Fragment> : null;
};