import { defineMessage } from "react-intl";

export const messages = defineMessage({
  add: {
    id: "Form.Repeat_Add",
    defaultMessage: "Add {type}",
  },
  remove: {
    id: "Form.Repeat_remove",
    defaultMessage: "Remove {type}",
  },
});
