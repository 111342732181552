import { ToggleState } from '../types/types'

const initialState: ToggleState = {
};


export const toggleReducer = (state = initialState, action: {type: string, payload: string}) => {
  switch (action.type) {
    case 'SHOW':
      return {...state, [action.payload]: true};
    case 'HIDE':
      return {...state, [action.payload]: false};
    case  'REMOVEUUIDS':
    return {...state, [action.payload]: true}
    case 'CANCEL_FETCH_COMPLETE':
      return {...state, 'CANCEL_TOGGLE': true}
    default:
      return state;
  }
};