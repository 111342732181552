import React from 'react';

interface LoadingProps {
  styles?: string
}

export const Loading: React.FC<LoadingProps> = ({ styles }) => {

  const style = styles ? styles : null

  return (
    <div className={`flex justify-center mt-96 ${style}`}>
        <section className='text-brand-dark_blue'>
	        <span className='loader loader-bars'>
                <span>
                </span>
            </span>
	    </section>
    </div>
  );
}

export default Loading;
